import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit";
import { IChannel, IConversation, ISttLanguage, ISttMessage, ITtsVoice, Snapshot } from "../../models/signalRModels";

export interface StsState {
    channels: IChannel[];
    conversations: IConversation[];
    selectedConversation?: IConversation;
    ttsVoices: { [key: string]: ITtsVoice[] };
    sttLanguages: { [key: string]: ISttLanguage[] };
}

const initialState: StsState = {

    /*channels: [{
        channelId: '1',
        fromNumber: '+36305489647',
        language: 'en-US',
        ttsVoice: 'en-US-GuyNeural',
        demoScenario: 'livetranslation',
        sttEngine: 'azure',
        ttsEngine: 'azure',
        transcript: [{ key: '1', channelId: '1', confidence: 0.8, originalText: 'Hello', translatedText: 'Hello', fromOtherChannelId: null, isInProgress: false, timestamp: new Date().toISOString(), isTtsPlaying: true}],
    },
    {
        channelId: '2',
        fromNumber: '+36305489649',
        language: 'es-ES',
        ttsVoice: 'es-ES-AlvaroNeural',
        demoScenario: 'livetranslation',
        sttEngine: 'azure',
        ttsEngine: 'azure',
        transcript: [{ key: '2', channelId: '2', confidence: 0.9, originalText: 'Hola', translatedText: 'Hola', fromOtherChannelId: null, isInProgress: false, timestamp: new Date().toISOString(), isTtsPlaying: true}],
    }],
    selectedConversation: { channelId1: '1', channelId2: '2', conversationId: 'conversationId', scenario: 'livetranslation' },
    conversations: [{ channelId1: '1', channelId2: '2', conversationId: 'conversationId', scenario: 'livetranslation' }],
*/
   channels: [],
    conversations: [],
    selectedConversation: undefined,
    
    ttsVoices: {},
    sttLanguages: {},
};

const stsSlice = createSlice({
    name: 'sts',
    initialState,
    reducers: {
        receiveState: (state, { payload }: PayloadAction<Snapshot>) => {
            state.channels = payload.channels;
            state.conversations = payload.conversations;
            if (payload.conversations.length > 0) {
                state.selectedConversation = payload.conversations[0];
            }
        },
        receiveIntermediateMessage: (state, { payload }: PayloadAction<ISttMessage>) => {
            const channel = state.channels.find(p => p.channelId === payload.channelId);
            if (!channel) return;
            var existingStreamingMessage = channel.transcript.find(m => m.isInProgress);
            if (existingStreamingMessage) {
                existingStreamingMessage.originalText = payload.originalText;
                existingStreamingMessage.translatedText = payload.translatedText;
                existingStreamingMessage.confidence = payload.confidence;
                existingStreamingMessage.timestamp = payload.timestamp;
            } else {
                const message: ISttMessage = { ...payload, key: nanoid() };
                channel.transcript.push(message);
            }
        },
        receiveFinalMessage: (state, { payload }: PayloadAction<ISttMessage>) => {
            const channel = state.channels.find(p => p.channelId === payload.channelId);
            if (!channel) return;
            channel.transcript = channel.transcript.filter(m => !m.isInProgress);
            channel.transcript.push({ ...payload, key: nanoid() });
        },
        receiveTtsPlaybackEvent: (state, { payload }: PayloadAction<{ channelId: string, isPlaying: boolean }>) => {
            const channel = state.channels.find(p => p.channelId === payload.channelId);
            if (!channel) return;
            channel.transcript.forEach(m => {
                if (m.isTtsPlaying) {
                    m.isTtsPlaying = false;
                }
            });
            if (payload.isPlaying) {
                const lastMessage = channel.transcript[channel.transcript.length - 1];
                if (lastMessage) {
                    lastMessage.isTtsPlaying = true;
                }
            }
        },
        channelCreated: (state, { payload }: PayloadAction<IChannel>) => {
            state.channels.push(payload);
        },
        channelRemoved: (state, { payload }: PayloadAction<IChannel>) => {
            state.channels = state.channels.filter(p => p.channelId !== payload.channelId);
        },
        channelUpdated: (state, { payload }: PayloadAction<IChannel>) => {
            const participant = state.channels.find(p => p.channelId === payload.channelId);
            if (participant) {
                if (payload.language)
                    participant.language = payload.language;
                if (payload.ttsVoice)
                    participant.ttsVoice = payload.ttsVoice;
                if (payload.sttEngine)
                    participant.sttEngine = payload.sttEngine;
                if (payload.ttsEngine)
                    participant.ttsEngine = payload.ttsEngine;
            }
        },
        conversationStarted: (state, { payload }: PayloadAction<IConversation>) => {
            // check if the conversation already exists
            if (state.conversations.find(c => c.conversationId === payload.conversationId)) {
                return;
            }
            state.conversations.push(payload);
            const urlParams = new URLSearchParams(window.location.search);
            const demoScenario = urlParams.get('scenario') ?? 'livetranslation';
            if (!state.selectedConversation && payload.scenario.toLowerCase() === demoScenario.toLowerCase()) {
                state.selectedConversation = payload;
            }
        },
        conversationEnded: (state, { payload }: PayloadAction<string>) => {
            state.conversations = state.conversations.filter(c => c.conversationId !== payload);
            if (state.selectedConversation?.conversationId === payload) {
                state.selectedConversation = undefined;
            }
        },
        setSelectedConversation: (state, { payload }: PayloadAction<IConversation>) => {
            state.selectedConversation = payload;
        },
        updateTtsVoices: (state, { payload }: PayloadAction<{ [key: string]: ITtsVoice[] }>) => {
            state.ttsVoices = payload;
        },
        updateSttLanguages: (state, { payload }: PayloadAction<{ [key: string]: ISttLanguage[] }>) => {
            state.sttLanguages = payload;
        }
    },
});

export const {
    receiveState,
    receiveIntermediateMessage,
    receiveFinalMessage,
    receiveTtsPlaybackEvent,
    channelCreated,
    channelRemoved,
    channelUpdated,
    conversationStarted,
    conversationEnded,
    setSelectedConversation,
    updateTtsVoices,
    updateSttLanguages
} = stsSlice.actions;

export default stsSlice.reducer;