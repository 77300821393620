import { FC } from "react";
import { ISttMessage } from "../models/signalRModels";
import TextMessage from "./TextMessage";

interface IProps {
    message: ISttMessage
}

const ChatItem: FC<IProps> = ({ message }) => {

    function generateTimestamp(): string {
        // return hours and minutes
        return new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    return <TextMessage
        timestamp={generateTimestamp()}
        text={message.fromOtherChannelId == null ? message.originalText : message.translatedText ?? message.originalText}
        isMine={message.fromOtherChannelId == null}
        confidence={message.confidence}
        isStreaming={message.isInProgress}
        isPlaying={message.isTtsPlaying}
    />;
};

export default ChatItem;