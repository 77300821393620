import EmojiPicker from "@emoji-mart/react";
import { getLuminance } from "polished";
import { FC, useState, useRef, useEffect, useCallback } from "react";
import { styled, useTheme } from "styled-components";
import data from '@emoji-mart/data';
import { Select } from "antd";

interface Emoji {
    native: string
}

const InputBoxContainer = styled.div`
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    background-color: ${props => props.theme.inputBoxBackground};
    border-top: 1px solid ${props => getLuminance(props.theme.inputBoxBackground) > 0.5 ? 'lightgray' : 'darkgray'};
`;

const StyledSendButton = styled.button`
  flex: 1 1;
  border: 0;
  background-color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0rem;
  padding-right: 0.3rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? `${props.theme.actionButton}80` : props.theme.actionButton};

  transition: all 0.2s ease-in-out;
`;

const StyledInputField = styled.input`
  width: 93%;
  color: ${props => props.theme.incomingMessageFont};
  background-color: #FFFFFF;
  border-radius: 0.3rem;
  padding: 0.2rem 0.3rem;
  font-size: 0.9rem;
  border: 0;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  &:focus {
      outline: none;
  };
  ::placeholder{
      color: ${props => props.theme.incomingMessageFont};
      opacity: 80%;
  }
`;

const SendIcon = styled.svg<{ $direction: string }>`
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: .2rem;
  transform: rotate(${p => p.$direction === 'rtl' ? '-90deg' : '90deg'});
`;

const volumeValues = [
    { value: 0, label: <span>0%</span> },
    { value: 1, label: <span>1%</span> },
    { value: 5, label: <span>5%</span> },
    { value: 10, label: <span>10%</span> },
    { value: 25, label: <span>25%</span> },
    { value: 50, label: <span>50%</span> },
    { value: 75, label: <span>75%</span> },
    { value: 100, label: <span>100%</span> }
];

interface IProps {
    inputValue: string;
    onSubmit(): void
    onTextChange(text: string): void
    onVolumeChange(volume: number): void
}

const InputBox: FC<IProps> = ({ inputValue, onSubmit, onTextChange, onVolumeChange }) => {
    const textInputRef = useRef<HTMLInputElement>(null);

    const submitOnEnter: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
        e => {
            if (e.key === 'Enter') {
                onSubmit();
            }
        },
        [onSubmit],
    );

    return (<InputBoxContainer>
        
        <StyledInputField
            id='bz_message_input'
            className='outline-none'
            placeholder='Type a message...'
            value={inputValue}
            onChange={(e) => onTextChange(e.target.value)}
            ref={textInputRef}
            onKeyDown={submitOnEnter}
            maxLength={9000}
        />
        <StyledSendButton disabled={!inputValue} onClick={onSubmit}>
            <SendIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.8} $direction={'ltr'}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </SendIcon>
        </StyledSendButton>
        <Select options={volumeValues} defaultValue={25} onChange={(e) => onVolumeChange(e)} aria-label="Volume" title="Volume"/>
    </InputBoxContainer>
    );
}

export default InputBox;