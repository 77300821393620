import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { defaultColorTheme } from '../models/Configuration';
import useMediaQuery from '../hooks/useMediaQuery';
import useStsHub from '../signalr/useStsHub';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import scrollIntoView from 'scroll-into-view-if-needed';
import ChatBoxLayout from '../layouts/ChatBoxLayout';
import ChatMessagesContainer from './chatMessagesContainer';
import { IConversation, IChannel, ISttMessage, Snapshot, IConversationEnded, ITtsEvent } from '../models/signalRModels';
import { conversationStarted, conversationEnded, receiveState, setSelectedConversation, updateSttLanguages, updateTtsVoices, channelRemoved, channelCreated, receiveFinalMessage, receiveIntermediateMessage, channelUpdated, receiveTtsPlaybackEvent } from '../redux/sts/ststSlice';
import TranslatedMessagesContainer from './translatedMessagesContainer';
import { Select } from 'antd';
import EmbeddedMessagesContainer from './embeddedMessagesContainer';
import BeHeader from '../presenters/beHeader';
import { baseUrl } from '../config/env';

const StsRoot = styled.div`
    width: 100vw;
    height: 100vh;
`;

const StsMainContent = styled.div`
    width: 100%;
    height: 100%;
`;

const StsContent = styled.div`
    padding: 0.8rem;
    background-color: #f5f5f5;
    overflow: hidden;
    height: calc(100% - 3rem);
`;
const ConversationLayout = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.3rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
`;

// top center absolute position width 10rem height 2rem
const ConversationSwitcher = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 13rem;
    height: 2rem;
    background-color: #f5f5f5;
    border: 1px solid #d3d3d3;
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;


// agent - anglo- customer
// change language
// change voice (deepgram)
// Encoding test
// 2 seconds
const StsContainer: FC = () => {
    const theme = defaultColorTheme;

    const dispatch = useAppDispatch();
    const _channels = useAppSelector(state => state.sts.channels);
    const _conversations = useAppSelector(state => state.sts.conversations);
    const selectedConversation = useAppSelector(state => state.sts.selectedConversation);
    const availableLanguages = useAppSelector(state => state.sts.sttLanguages);
    const availableVoices = useAppSelector(state => state.sts.ttsVoices);

    // get from url query param named scenario
    const selectedDemoScenario = useMemo(
        () => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('scenario') ?? 'livetranslation';
        },
        [],
    );

    const isEmbeddedInsideBuzzeasy = useMemo(
        () => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('embedded') === 'true';
        },
        [],
    );

    const conversations = useMemo(
        () => _conversations.filter(p => p.scenario.toLowerCase() === selectedDemoScenario.toLowerCase()),
        [_conversations, selectedDemoScenario],
    );


    // filter participants by demo scenario
    const channels = useMemo(
        () => _channels.filter(p => p.demoScenario.toLowerCase() === selectedDemoScenario.toLowerCase()),
        [_channels, selectedDemoScenario],
    );

    const conversationOptions = useMemo(
        () => conversations.map((c, i) => ({ label: `Conversation ${i + 1}`, value: c.conversationId })),
        [conversations],
    );

    const [isMobileView, setMobileView] = useState(false);

    const selectedChannel1 = useMemo(
        () => {
            const channel = channels.find(p => p.channelId === selectedConversation?.channelId1);
            console.log('selectedChannel1', channel);
            console.log('selectedChannel1 selectedConversation', selectedConversation);
            console.log('selectedChannel1 channels', channels);
            return channel ?? null;
        },
        [channels, selectedConversation],
    );

    const selectedChannel2 = useMemo(
        () => channels.find(p => p.channelId === selectedConversation?.channelId2) ?? null,
        [channels, selectedConversation],
    );

    useEffect(() => {
        console.log('channels', channels);
        console.log('conversations', conversations);
        console.log('selectedConversation', selectedConversation);
        console.log('selectedChannel1', selectedChannel1);
        console.log('selectedChannel2', selectedChannel2);
    }, [channels, conversations, selectedChannel1, selectedChannel2, selectedConversation]);


    const handleWithViewportChanges = useCallback(
        (isMatch: boolean) => {
            setMobileView(isMatch);
        },
        [],
    );

    const scrollRef1 = useRef<HTMLDivElement>(null);
    const scrollRef2 = useRef<HTMLDivElement>(null);
    const scrollRef3 = useRef<HTMLDivElement>(null);

    const scrollToBottom = useCallback(
        () => setTimeout(
            () => {
                const current1 = scrollRef1.current;
                if (current1) {
                    scrollIntoView(current1, { behavior: 'smooth', block: 'nearest' });
                }
                const current2 = scrollRef2.current;
                if (current2) {
                    scrollIntoView(current2, { behavior: 'smooth', block: 'nearest' });
                }
                const current3 = scrollRef3.current;
                if (current3) {
                    scrollIntoView(current3, { behavior: 'smooth', block: 'nearest' });
                }
            },
            100,
        ),
        [],
    );

    const handleInitStateReceived = useCallback(
        (initState: Snapshot) => {
            dispatch(receiveState(initState));
            scrollToBottom();
        },
        [dispatch, scrollToBottom],
    );

    const handleMessageReceived = useCallback(
        (message: ISttMessage) => {
            dispatch(receiveIntermediateMessage(message));
            scrollToBottom();
        },
        [dispatch, scrollToBottom],
    );

    const handleFinalMessageReceived = useCallback(
        (message: ISttMessage) => {
            dispatch(receiveFinalMessage(message));
            scrollToBottom();
        },
        [dispatch, scrollToBottom],
    );

    const handleChannelCreated = useCallback(
        (channel: IChannel) => {
            dispatch(channelCreated(channel));
        },
        [dispatch],
    );

    const handleChannelRemoved = useCallback(
        (channel: IChannel) => {
            dispatch(channelRemoved(channel));
        },
        [dispatch],
    );

    const handleConversationStarted = useCallback(
        (conversation: IConversation) => {
            dispatch(conversationStarted(conversation));
        },
        [dispatch],
    );

    const handleParticipantUpdated = useCallback(
        (conversationEndedEvent: IConversationEnded) => {
            dispatch(conversationEnded(conversationEndedEvent.conversationId))
        },
        [dispatch],
    );

    const handleTtsPlaybackEvent = useCallback(
        (ttsEvent: ITtsEvent) => {
           dispatch(receiveTtsPlaybackEvent(ttsEvent));
        },
        [dispatch],
    );

    const copilotHub = useStsHub({
        onReceiveState: handleInitStateReceived,
        onReceiveInProgressMessage: handleMessageReceived,
        onReceiveFinalMessage: handleFinalMessageReceived,
        onChannelCreated: handleChannelCreated,
        onChannelRemoved: handleChannelRemoved,
        onConversationStarted: handleConversationStarted,
        onConversationEnded: handleParticipantUpdated,
        onReceiveTtsPlaybackEvent: handleTtsPlaybackEvent,
    });

    const changeLanguage = useCallback(
        (channelId: string, language: string) => {
            copilotHub.changeLanguage(channelId, language);
            const channel = channels.find(c => c.channelId === channelId);
            if (!channel) return;
            const updatedChannel = { ...channel, language };
            dispatch(channelUpdated(updatedChannel));
        },
        [channels, copilotHub, dispatch],
    );

    const changeVoice = useCallback(
        (channelId: string, voice: string) => {
            copilotHub.changeTtsVoice(channelId, voice);
            const channel = channels.find(c => c.channelId === channelId);
            if (!channel) return;
            const updatedChannel = { ...channel, ttsVoice: voice };
            dispatch(channelUpdated(updatedChannel));
        },
        [channels, copilotHub, dispatch],
    );

    const changeSttEngine = useCallback(
        (channelId: string, engineType: string) => {
            copilotHub.changeSttEngine(channelId, engineType);
        },
        [copilotHub],
    );

    const changeTtsEngine = useCallback(
        (channelId: string, engineType: string) => {
            copilotHub.changeTtsEngine(channelId, engineType);
        },
        [copilotHub],
    );

    const changeConversation = useCallback(
        (value: { value: string; label: React.ReactNode }) => {

            if (typeof value === 'string') {
                const conversation = conversations.find(c => c.channelId1 === value);
                if (!conversation) return;
                dispatch(setSelectedConversation(conversation));
            } else if (typeof value === 'object' && value !== null) {
                const conversation = conversations.find(c => c.channelId1 === value.value);
                if (!conversation) return;
                dispatch(setSelectedConversation(conversation));
            }
        }
        ,
        [conversations, dispatch],
    );

    useMediaQuery('(max-height: 200px)', handleWithViewportChanges);

    const fetchVoices = useCallback(
        async () => {
            try {
                const azureVoices = await fetch(baseUrl + `/voice/azure/available-voices`);
                const deepgramVoices = await fetch(baseUrl + `/voice/deepgram/available-voices`);
                dispatch(updateTtsVoices({
                    azure: azureVoices.ok ? await azureVoices.json() : [],
                    deepgram: deepgramVoices.ok ? await deepgramVoices.json() : [],
                }));
            } catch (error) {
                console.error(error);
            }
        },
        [dispatch],
    );

    const fetchLanguages = useCallback(
        async () => {
            try {
                const azureLanguages = await fetch(baseUrl + `/speech/azure/available-languages`);
                const deepgramLanguages = await fetch(baseUrl + `/speech/deepgram/available-languages`);
                const googleLanguages = await fetch(baseUrl + `/speech/google/available-languages`);
                dispatch(updateSttLanguages({
                    azure: azureLanguages.ok ? await azureLanguages.json() : [],
                    deepgram: deepgramLanguages.ok ? await deepgramLanguages.json() : [],
                    google: googleLanguages.ok ? await googleLanguages.json() : [],
                }));
            } catch (error) {
                console.error(error);
            }
        },
        [dispatch],
    );

    useEffect(() => {
        copilotHub.start();
        if (Object.keys(availableLanguages).length === 0) {
            fetchLanguages();
        }
        if (Object.keys(availableVoices).length === 0) {
            fetchVoices();
        }
    }, [availableLanguages, availableVoices, copilotHub, fetchLanguages, fetchVoices]);

    return (
        <ThemeProvider theme={theme}>
            {
                isMobileView ?
                    <div>Not enough space to display chat</div>
                    : (<StsRoot>
                        {!isEmbeddedInsideBuzzeasy ?
                            <StsMainContent>
                                <BeHeader />
                                <StsContent>
                                    {!selectedConversation && channels.length > 0 ?
                                        <ChatBoxLayout
                                            messages={<ChatMessagesContainer
                                                innerRef={scrollRef1}
                                                changeLanguage={changeLanguage}
                                                changeVoice={changeVoice}
                                                changeSttEngine={changeSttEngine}
                                                changeTtsEngine={changeTtsEngine}
                                                participantName='Participant 1'
                                                reverseSender={false}
                                                channel={channels[0]}
                                                displayLanguageSelector={true} />}
                                        />
                                        :
                                        selectedConversation ? <ConversationLayout>
                                            {conversationOptions.length > 1 ? <ConversationSwitcher>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    options={conversationOptions}
                                                    defaultValue={conversationOptions.find(c => c.value === selectedConversation.conversationId)}
                                                    onChange={changeConversation} />
                                            </ConversationSwitcher> : <></>}
                                            <ChatBoxLayout
                                                messages={<ChatMessagesContainer
                                                    changeLanguage={changeLanguage}
                                                    changeVoice={changeVoice}
                                                    changeSttEngine={changeSttEngine}
                                                    changeTtsEngine={changeTtsEngine}
                                                    innerRef={scrollRef1}
                                                    reverseSender={false}
                                                    participantName='Participant 1'
                                                    channel={selectedChannel1}
                                                    displayLanguageSelector={true} />}
                                            />
                                            <ChatBoxLayout messages={<TranslatedMessagesContainer
                                                innerRef={scrollRef2}
                                                channel1={selectedChannel1}
                                                channel2={selectedChannel2} />} />
                                            <ChatBoxLayout
                                                messages={<ChatMessagesContainer
                                                    innerRef={scrollRef3}
                                                    changeLanguage={changeLanguage}
                                                    changeVoice={changeVoice}
                                                    changeSttEngine={changeSttEngine}
                                                    changeTtsEngine={changeTtsEngine}
                                                    reverseSender={true}
                                                    participantName='Participant 2'
                                                    channel={selectedChannel2}
                                                    displayLanguageSelector={true} />}
                                            />
                                        </ConversationLayout>
                                            : <div>No participants</div>}


                                </StsContent>
                            </StsMainContent> :
                            <EmbeddedMessagesContainer
                                agent={selectedChannel2}
                                customer={selectedChannel1}
                                hub={copilotHub}
                                innerRef={scrollRef1} />}
                    </StsRoot>)
            }
        </ThemeProvider>

    );
}

export default StsContainer;
