import StsContainer from './containers/stsContainer';
import styled from 'styled-components';

const StaticStyles = styled.div`
  font-size: 0.75rem;
  line-height: 1.4286;
  * { box-sizing: border-box; }
  *:before { box-sizing: border-box; }
  *:after { box-sizing: border-box; }
  [hidden] { display: 'none!important'; }
  * { 
      -webkit-tap-highlight-color: transparent;
      -moz-tap-highlight-color: transparent;
    }
`;

function App() {
  return (
    <StaticStyles>
      <StsContainer />
    </StaticStyles >
  );
}

export default App;
