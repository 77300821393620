import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IChannel, IChannelRemoved, IConversation, IConversationEnded, ISttMessage, ITtsEvent, Snapshot } from "../models/signalRModels";
import { baseUrl } from "../config/env";

interface Method {
    name: string;
    handler(data: unknown): void;
}

interface IHandlers {
    onReceiveState(state: Snapshot): void;
    onReceiveInProgressMessage(message: ISttMessage): void;
    onReceiveFinalMessage(message: ISttMessage): void;
    onReceiveTtsPlaybackEvent(ttsEvent: ITtsEvent): void;
    
    onChannelCreated(channel: IChannel): void;
    onChannelRemoved(channelRemoved: IChannelRemoved): void;
    onConversationStarted(conversation: IConversation): void;
    onConversationEnded(conversationEnded: IConversationEnded): void;
}

export interface IStsHub {
    start(): Promise<unknown>;
    disconnect(): void;
    changeLanguage(channelId: string, language: string): void;
    changeSttEngine(channelId: string, engineType: string): void;
    changeTtsVoice(channelId: string, ttsVoice: string): void;
    changeTtsEngine(channelId: string, engineType: string): void;
    sendMessage(channelId: string, message: string): void;
    changeVolume(channelId: string, volume: number): void;
}

const useStsHub = ({
    onReceiveState,
    onReceiveInProgressMessage,
    onReceiveFinalMessage,
    onChannelCreated,
    onChannelRemoved,
    onConversationStarted,
    onConversationEnded,
    onReceiveTtsPlaybackEvent,
}: IHandlers): IStsHub => {
    // NOTE: connection mutates internally
    const [connection, setConnection] = useState<HubConnection>();

    const [messageQueue, setMessageQueue] = useState<{ method: string; arg?: unknown }[]>([]);

    useEffect(
        () => {
            if (connection?.state === HubConnectionState.Connected && messageQueue.length > 0) {
                messageQueue.forEach(m => connection.send(m.method, m.arg));
                setMessageQueue([]);
            }
        },
        [connection, connection?.state, messageQueue],
    );

    useEffect(
        () => {

            const hubUrl =  baseUrl + "/hub";
            const newConnection = new HubConnectionBuilder()
                .withUrl(hubUrl)
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);

            return () => {
                if (newConnection.state === HubConnectionState.Connected)
                    newConnection.stop();
            };
        },
        [],
    );

    const handlers = useMemo<Method[]>(
        () => {
            return [
                { name: 'ReceiveState', handler: onReceiveState },
                { name: 'ReceiveInProgressMessage', handler: onReceiveInProgressMessage },
                { name: 'ReceiveFinalMessage', handler: onReceiveFinalMessage },
                { name: 'ChannelCreated', handler: onChannelCreated },
                { name: 'ChannelRemoved', handler: onChannelRemoved },
                { name: 'ConversationStarted', handler: onConversationStarted },
                { name: 'ConversationEnded', handler: onConversationEnded},
                { name: 'ReceiveTtsPlaybackEvent', handler: onReceiveTtsPlaybackEvent}
            ] as Method[];
        },
        [
            onReceiveState,
            onReceiveInProgressMessage,
            onReceiveFinalMessage,
            onReceiveTtsPlaybackEvent,
            onChannelCreated,
            onChannelRemoved,
            onConversationStarted,
            onConversationEnded,
        ],
    );

    useEffect(
        () => {
            handlers.forEach(m => connection?.on(m.name, m.handler));
        },
        [connection, handlers],
    );

    const start = useCallback(
        async () => {
            if (connection?.state === HubConnectionState.Disconnected) {
                try {
                    await connection.start();
                }
                catch {
                    console.log('Error connecting to sts hub');
                }
            }
            return connection;
        },
        [connection],
    );

    const disconnect = useCallback(
        () => {
            connection?.stop();
        },
        [connection],
    );

    const changeLanguage = useCallback(
        (channelId: string, language: string) => {
            connection?.send('ChangeLanguage', { channelId: channelId, languageCode: language });
        },
        [connection],
    );

    const changeTtsVoice = useCallback(
        (channelId: string, ttsVoice: string) => {
            connection?.send('ChangeTtsVoice', { channelId: channelId, ttsVoiceCode: ttsVoice });
        },
        [connection],
    );

    const changeSttEngine = useCallback(
        (channelId: string, engineType: string) => {
            connection?.send('ChangeSttEngine', { channelId: channelId, engineType: engineType });
        },
        [connection],
    );

    const changeTtsEngine = useCallback(
        (channelId: string, engineType: string) => {
            connection?.send('ChangeTtsEngine', { channelId: channelId, engineType: engineType });
        },
        [connection],
    );

    const sendMessage = useCallback(
        (fromChannelId: string, message: string) => {
            connection?.send('SendMessage', { fromChannelId: fromChannelId, text: message });
        },
        [connection],
    );

    const changeVolume = useCallback(
        (channelId: string, volume: number) => {
            connection?.send('ChangeBackgroundAudioVolume', { channelId: channelId, volume: volume });
        },
        [connection],
    );

    return useMemo<IStsHub>(
        () => ({
            start,
            disconnect,
            changeLanguage,
            changeTtsVoice,
            changeSttEngine,
            changeTtsEngine,
            sendMessage,
            changeVolume,
        }),
        [start, disconnect, changeLanguage, changeTtsVoice, changeSttEngine, changeTtsEngine, sendMessage, changeVolume],
    );
};

export default useStsHub;