export type Color = `#${string}`;

export interface ITheme {
    background: Color;
    actionButton: Color;
    headerBackground: Color;
    headerFont: Color;
    outgoingMessageBackground: Color;
    outgoingMessageBorder: Color;
    outgoingMessageFont: Color;
    incomingMessageBackground: Color;
    incomingMessageBorder: Color;
    incomingMessageFont: Color;
    suggestedActionBackground: Color;
    suggestedActionFont: Color;
    inputBoxBackground: Color;
}

export const defaultColorTheme: ITheme = {
    background: '#ffffff',
    actionButton: '#326ec2',
    headerBackground: '#326ec2',
    headerFont: '#ffffff',
    outgoingMessageBackground: '#326ec2',
    outgoingMessageBorder: '#326ec2',
    outgoingMessageFont: '#f3f2f1',
    incomingMessageBackground: '#f3f2f1',
    incomingMessageBorder: '#b3b3b3',
    incomingMessageFont: '#201f1e',
    suggestedActionBackground: '#ffffff',
    suggestedActionFont: '#323130',
    inputBoxBackground: '#F0F9FF',
};