import { darken } from "polished";
import { FC, useMemo } from "react";
import styled from "styled-components";
import ChatMessagesLayout from "../layouts/ChatMessagesLayout";
import { IChannel, ISttMessage } from "../models/signalRModels";
import { useAppSelector } from "../redux/hooks";
import ChatItem from "../presenters/ChatItem";
import Title from "antd/es/typography/Title";
import { Flex } from "antd";

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.border};
  border-color: #d3d3d3;
  border-radius: 0.375rem; /* 6px */
  overflow: hidden;
  height: 100%;
  padding-top: 0.8rem;
  background-color: ${props => props.theme.background};
`;

const ChatContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.background};

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.headerBackground};
    border-radius: 9999px;
    cursor: pointer;

    :hover {
      background-color: ${props => darken(0.05, props.theme.headerBackground)};
    }
  }
`;

interface IProps {
    innerRef: React.RefObject<HTMLDivElement>;
    channel1: IChannel | null;
    channel2: IChannel | null;
}

const TranslatedMessagesContainer: FC<IProps> = ({ innerRef, channel1, channel2 }) => {

    // merge messages from both channels which are not from other channel and sort them by timestamp and map them to ChatItem
    const items = useMemo(
        () => {
            if (!channel1 || !channel1.transcript || !channel2 || !channel2.transcript) return [];

            let messages = [...channel1.transcript, ...channel2.transcript].filter((msg: ISttMessage) => msg.fromOtherChannelId == null && msg.isInProgress === false && msg.confidence >= 0.5);

            messages = messages.map((msg: ISttMessage) => {
                const isFromSecondChannel = channel2.transcript.includes(msg);

                if (msg.translatedText) {
                    return { ...msg, text: msg.translatedText, fromOtherChannelId: isFromSecondChannel ? null : channel1.channelId, isTtsPlaying: false};
                }
                return msg;
            });

            return messages
                .sort((a: ISttMessage, b: ISttMessage) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
                .map((msg: ISttMessage) => (<ChatItem key={msg.key} message={msg} />));
        }
        , [channel1, channel2]
    );

    return (
        <RootContainer>
            <Flex justify={'center'} align={'center'}>
                <Title level={4} style={{ marginBottom: 0 }}>Intermediate English translation</Title>
            </Flex>
            <ChatContainer>
                <ChatMessagesLayout>
                    {items}
                </ChatMessagesLayout>
                <div
                    style={{ float: 'left', clear: 'both' }} // hidden div for scrolling
                    ref={innerRef}
                    role="none"
                />
            </ChatContainer>
        </RootContainer>
    );
};

export default TranslatedMessagesContainer;