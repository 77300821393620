import { configureStore } from "@reduxjs/toolkit";
import stsReducer  from "./sts/ststSlice";

export const store = configureStore({
    reducer: {
        sts: stsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;