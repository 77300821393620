import { FC } from "react";
import { styled } from "styled-components";

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 0.5rem;
    background-color: #326EC2;
    width: 100%;
    height: 3rem;
`;

const HeaderTitle = styled.h1`
    color: #fff;
    font-size: .9rem;
    font-weight: 300;
`;

const BeHeader: FC = () => {

    return  <Header>

    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 52.222 52.222" style={{ marginRight: '10px' }}>
        <g id="Group_1" data-name="Group 1" transform="translate(-0.001 0)">
            <path id="Path_1" data-name="Path 1" d="M37.7,33.464a3.16,3.16,0,0,0-2.369-.4,6.621,6.621,0,0,0-4.784,4.378C28.822,42.1,31.2,47.2,36.431,47.881c.209-.424.472-.957.756-1.559.066-.137.859-2.564.859-2.564a20.46,20.46,0,0,0,.862-4.078,14.352,14.352,0,0,0,.049-4.1A3.27,3.27,0,0,0,37.7,33.464" transform="translate(-8.633 -9.498)" fill="#fff" />
            <path id="Path_2" data-name="Path 2" d="M29.094,50.594c.011.044.021.338.034.653a14.881,14.881,0,0,0,.215,1.644,8.27,8.27,0,0,0,.612,1.949,3.664,3.664,0,0,0,.64.988,2.14,2.14,0,0,0,2.957.306A6.8,6.8,0,0,0,35.4,54.24c-.372-.072-.753-.157-1.145-.26A10.562,10.562,0,0,1,29.1,50.594" transform="translate(-8.375 -14.564)" fill="#fff" />
            <path id="Path_3" data-name="Path 3" d="M34.547,19.317c.13-.393.254-.786.366-1.183a.061.061,0,0,1,.007-.027,16.434,16.434,0,0,0,.63-3.594c0-.048.007-.1.007-.143a4.254,4.254,0,0,0-.116-1.211.568.568,0,0,0-.464-.468.553.553,0,0,0-.544.3,4.206,4.206,0,0,0-.322.482c-.027.045-.048.089-.071.13a12.046,12.046,0,0,0-.91,2.362,77.453,77.453,0,0,0-1.961,8.581q-.2,1.139-.4,2.287a30.917,30.917,0,0,0,1.8-2.95,29.338,29.338,0,0,0,1.983-4.571" transform="translate(-8.857 -3.652)" fill="#fff" />
            <path id="Path_4" data-name="Path 4" d="M26.115,0A26.111,26.111,0,1,0,52.223,26.111,26.112,26.112,0,0,0,26.115,0m12.1,36.984a9.882,9.882,0,0,1-6.268,2.928,12.865,12.865,0,0,1-4.1,3.774,7.234,7.234,0,0,1-5.976.881,5.841,5.841,0,0,1-1.186-.52,7.07,7.07,0,0,1-2.577-2.783,17.9,17.9,0,0,1-1.975-8.945A45.928,45.928,0,0,1,17,23.872a.042.042,0,0,1,0-.021c.055-.318.113-.636.174-.951a42.836,42.836,0,0,1,2.4-8.42c.254-.619.513-1.234.8-1.839A13.945,13.945,0,0,1,23.66,8.023a4.222,4.222,0,0,1,1.822-1A2.679,2.679,0,0,1,28.628,8.64a7.732,7.732,0,0,1,.255,4.3,20.907,20.907,0,0,1-2.476,6.646c-.6,1.247-1.279,2.468-1.926,3.658a8.059,8.059,0,0,1,4.5-2.044H29a5.167,5.167,0,0,1,4.858,2.062,8.779,8.779,0,0,1,1.6,5.016,17.023,17.023,0,0,1-.664,5.665,12.228,12.228,0,0,1-.506,1.381l-.793,1.946c-.178.362-.362.721-.561,1.073a9.079,9.079,0,0,0,5.283-3.322Z" fill="#fff" />
        </g>
    </svg>
    <HeaderTitle>Buzzeasy Speech-To-Speech Translation</HeaderTitle>
    </Header>
};

export default BeHeader;
