import { darken } from "polished";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ChatMessagesLayout from "../layouts/ChatMessagesLayout";
import { IChannel, ISttMessage } from "../models/signalRModels";
import { useAppSelector } from "../redux/hooks";
import ChatItem from "../presenters/ChatItem";
import { Flex, Form, Select, Switch } from "antd";
import Title from "antd/es/typography/Title";

const RootContainer = styled.div<{ $visible: boolean }>`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  height: 100%;
  border: 1px solid ${props => props.theme.border};
  border-color: #d3d3d3;
  border-radius: 0.375rem; /* 6px */
  overflow: hidden;
  padding-top: 0.8rem;
  background-color: ${props => props.theme.background};
`;

const FormContainer = styled.div`
  padding: 0.5rem;
  width: 25rem;
`;

const ChatContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.background};

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.headerBackground};
    border-radius: 9999px;
    cursor: pointer;

    :hover {
      background-color: ${props => darken(0.05, props.theme.headerBackground)};
    }
  }
`;

interface IProps {
  innerRef: React.RefObject<HTMLDivElement>;
  displayLanguageSelector: boolean;
  participantName: string;
  reverseSender: boolean;
  channel: IChannel | null;
  changeLanguage: (channelId: string, language: string) => void;
  changeVoice: (channelId: string, voice: string) => void;
  changeSttEngine: (channelId: string, engineType: string) => void;
  changeTtsEngine: (channelId: string, engineType: string) => void;
}

const ChatMessagesContainer: FC<IProps> = ({ innerRef, displayLanguageSelector, participantName, channel, reverseSender, changeLanguage, changeVoice, changeSttEngine, changeTtsEngine }) => {

  const [selectedTtsEngine, setSelectedTtsEngine] = useState<string | undefined>(channel?.ttsEngine?.toLowerCase());
  const [selectedSttEngine, setSelectedSttEngine] = useState<string | undefined>(channel?.sttEngine?.toLowerCase());

  const [isVoiceSelectionRequired, setIsVoiceSelectionRequired] = useState<boolean>(false);

  useEffect(() => {
    console.log('Channel: ', channel);
  }, [channel]);

  const availableVoices = useAppSelector(state => state.sts.ttsVoices);
  const availableLanguages = useAppSelector(state => state.sts.sttLanguages);

  const filteredLanguages = useMemo(() => {
    return selectedSttEngine ? availableLanguages[selectedSttEngine] : []
  },
    [availableLanguages, selectedSttEngine],
  );

  const filteredVoices = useMemo(() => {
    return selectedTtsEngine ? availableVoices[selectedTtsEngine] : [];
  },
    [availableVoices, selectedTtsEngine],
  );

  const languages = useMemo(() => {
    if (!filteredLanguages) return [];

    return filteredLanguages.map(lang => {
      return { value: lang.code, label: lang.name };
    })
  },
    [filteredLanguages],
  );

  const defaultLanguage = useMemo(() => {
    if (!channel) return undefined;

    return languages.filter(lang => lang.value === channel.language)[0];
  }
    , [languages, channel],
  );


  const voices = useMemo(() => {
    if (!filteredVoices) return [];

    if (!defaultLanguage) {
      return filteredVoices.map(voice => {
        return { value: voice.code, label: voice.name };
      })
    }

    return filteredVoices.filter(voice => voice.code.startsWith(defaultLanguage.value.substring(0, 2))).map(voice => {
      return { value: voice.code, label: voice.name };
    })

  },
    [defaultLanguage, filteredVoices],
  );

  const defaultVoice = useMemo(() => {
    if (!channel || isVoiceSelectionRequired) return undefined;

    return voices.filter(voice => voice.value === channel.ttsVoice)[0];
  }
    , [channel, isVoiceSelectionRequired, voices],
  );

  const items = useMemo(
    () =>channel ? [...channel.transcript]
      .map((msg: ISttMessage) => {
        return { ...msg, isFromOtherParticipant: reverseSender ? msg.fromOtherChannelId === null : msg.fromOtherChannelId !== null};
      })
      .sort((a: ISttMessage, b: ISttMessage) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      .map((msg: ISttMessage) => (<ChatItem key={msg.key} message={msg} />)) : [],
    [channel, reverseSender],
  );

  const handleLanguageChange = useCallback(
    (value: { value: string; label: React.ReactNode }) => {
      if (!channel) return;

      if (typeof value === 'string') {
        changeLanguage(channel.channelId, value);
      } else if (typeof value === 'object' && value !== null) {
        changeLanguage(channel.channelId, value.value);
      }
      setIsVoiceSelectionRequired(true);
    },
    [changeLanguage, channel],
  );

  const handleVoiceChange = useCallback(
    (value: { value: string; label: React.ReactNode }) => {
      if (!channel) return;

      if (typeof value === 'string') {
        changeVoice(channel.channelId, value);
      } else if (typeof value === 'object' && value !== null) {
        changeVoice(channel.channelId, value.value);
      }
      setIsVoiceSelectionRequired(false);
    },
    [changeVoice, channel],
  );

  const handleSttEngineChange = useCallback(
    (value: { value: string; label: React.ReactNode }) => {
      if (!channel) return;

      if (typeof value === 'string') {
        changeSttEngine(channel.channelId, value);
        setSelectedSttEngine(value);
      } else if (typeof value === 'object' && value !== null) {
        changeSttEngine(channel.channelId, value.value);
        setSelectedSttEngine(value.value);
      }
    },
    [changeSttEngine, channel],
  );

  const handleTtsEngineChange = useCallback(
    (value: { value: string; label: React.ReactNode }) => {
      if (!channel) return;

      if (typeof value === 'string') {
        changeTtsEngine(channel.channelId, value);
        setSelectedTtsEngine(value);
      } else if (typeof value === 'object' && value !== null) {
        changeTtsEngine(channel.channelId, value.value);
        setSelectedTtsEngine(value.value);
      }
    },
    [changeTtsEngine, channel],
  );

  const providers = useMemo(() => {
    return [
      { value: 'azure', label: 'Azure' },
      { value: 'google', label: 'Google' },
      { value: 'deepgram', label: 'Deepgram' },
    ];
  }, []);

  /*
          <Form.Item label="Language" style={{ marginBottom: '0.8rem' }}>
          <Select
            showSearch
            options={languages}
            defaultValue={defaultLanguage}
            onChange={handleLanguageChange}
            filterOption={filterOption} />
        </Form.Item>

        <Form.Item label="Voice" style={{ marginBottom: '0.8' }}>
          <Select
            showSearch
            options={voices}
            value={defaultVoice}
            onChange={handleVoiceChange}
            filterOption={filterOption} />
        </Form.Item>

        <Form.Item label="TTS engine" style={{ marginBottom: '0.8rem' }}>
          <Select
            showSearch
            options={providers.filter(p => p.value !== 'google')}
            defaultValue={ providers.filter(p => p.value === selectedTtsEngine)[0]}
            onChange={handleTtsEngineChange}
            filterOption={filterOption} />
        </Form.Item>

                <Form.Item label="Speech engine" style={{ marginBottom: '0.8rem' }}>
          <Select
            showSearch
            options={providers}
            defaultValue={ providers.filter(p => p.value === selectedSttEngine)[0]}
            onChange={handleSttEngineChange}
            filterOption={filterOption} />
        </Form.Item>
  */

  const filterOption = useCallback((input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    , []);


  return (channel &&
    <RootContainer $visible={displayLanguageSelector}>
      <Flex justify={'center'} align={'center'}>
        <Title level={4} style={{ marginBottom: 0 }}>{participantName} ({channel.fromNumber})</Title>
      </Flex>
      <FormContainer>
      </FormContainer>
      <ChatContainer>
        <ChatMessagesLayout>
          {items}
        </ChatMessagesLayout>
        <div
          style={{ float: 'left', clear: 'both' }} // hidden div for scrolling
          ref={innerRef}
          role="none"
        />
      </ChatContainer>
    </RootContainer>
  );
};

export default ChatMessagesContainer;